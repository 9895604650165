<div class="flex items-center">
  <div
    class="flex flex-col rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] items-center"
  >
    <img
      class="rounded-lg w-52 h-auto"
      [src]="imageUrl"
      alt="QR Code image"
    />
    <div class="flex flex-col space-y-4 py-4">
      <div class="flex justify-center align-baseline">
      <h4 class="font-bold text-2xl text-center text-[#CEA063] mt-2">
        Il tuo QR code
      </h4>
      <button mat-icon-button (click)="downloadImage()"><mat-icon>download</mat-icon></button>
    </div>
      <span class="text-base text-center text-[#CEA063]">
        <a target="_blank" [href]="data" class="text-green-800">{{ data }} </a>
        <button mat-icon-button [cdkCopyToClipboard]="data"><mat-icon>content_copy</mat-icon></button>
      </span>
    </div>
  </div>
</div>
