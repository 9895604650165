import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserMetaService } from '@fi/app/client';
import { IUser } from '@fi/app/core';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { UsersService } from '@fi/app/core/services/users.service';
import { AppConfigService } from '@fi/app/core/app-config/app-config.service';
import { skip, switchMap } from 'rxjs/operators';
@Component({
  selector: 'facile-anagrafica-upsert',
  template: `
    <form *ngIf="user" [formGroup]="form" novalidate>
      <div class="rounded-sm border border-stroke bg-white shadow-default p-4">
        <div class="flex items-center gap-2">
          <h4 class="mt-2">{{ user.firstName }} {{ user.lastName }}</h4>
          <button
            mat-icon-button
            *permissions="['is_admin']"
            class="ml-2"
            (click)="directLogin()"
            pTooltip="Accedi come {{ user.firstName }} {{ user.lastName }} "
          >
            <mat-icon>login</mat-icon>
          </button>
        </div>

        <fullstack-input-user
          [imageStorePath]="apiGatewayURL + '/file-server/download/'"
          formControlName="user"
        ></fullstack-input-user>
        <div class="row mt-2">
          <div class="col-6">
            <div class="row">
              <div class="col-12 my-1" pTooltip="Abilitato alla professione di Agente d'affari in mediazione">
                <fullstack-input-check-box
                  formControlName="isQualified"
                  [options]="{ label: 'Abilitato' }"
                ></fullstack-input-check-box>
              </div>
              <div
                *permissions="['is_maintainer']"
                class="col-12 my-1"
                pTooltip="Imposta utenza come fittizia, non riceverà alert via email "
              >
                <fullstack-input-check-box
                  formControlName="isDummy"
                  [options]="{ label: 'Fittizia' }"
                ></fullstack-input-check-box>
              </div>
            </div>
            <div class="row">
              <div class="col-12 my-4">
                <div class="flex w-100 items-center">
                  <fullstack-input-box
                    class=" w-100"
                    formControlName="fiscalCode"
                    [options]="{ label: 'Codice fiscale' }"
                  ></fullstack-input-box>
                  <button mat-icon-button color="primary" (click)="updateFiscalCode()">
                    <mat-icon>save</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mt-2">
              <app-qr-show [data]="'https://terapartners.it/landing/lead-prospect/?aid=' + user?.id"></app-qr-show>
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class UtentiUpsertComponent implements OnInit {
  form = this.fb.group({
    user: new UntypedFormControl(null, []),
    isQualified: new UntypedFormControl(false, []),
    isDummy: new UntypedFormControl(false, []),
    fiscalCode: new UntypedFormControl('', []),
  });

  user: IUser;
  id: string = null;
  apiGatewayURL;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private authServices: AuthServices,
    private appConfig: AppConfigService,
    private userMetaService: UserMetaService,
  ) {}

  ngOnInit(): void {
    this.apiGatewayURL = this.appConfig.getApiGatewayURL();

    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.userMetaService.findByFusionUserId(this.id).subscribe((userMeta) => {
          this.form.patchValue({
            user: userMeta.userObj,
            isQualified: userMeta?.isQualified,
            isDummy: userMeta?.isDummy,
            fiscalCode: userMeta?.fiscalCode,
          });
          this.user = userMeta.userObj;
        });
      }
    });
    this.form
      .get('isQualified')
      .valueChanges.pipe(
        skip(1),
        switchMap((value) => this.usersService.updateUserMeta(this.id, { isQualified: value })),
      )
      .subscribe();
    this.form
      .get('isDummy')
      .valueChanges.pipe(
        skip(1),
        switchMap((value) => this.usersService.updateUserMeta(this.id, { isDummy: value })),
      )
      .subscribe();
  }

  updateFiscalCode() {
    this.usersService.updateUserMeta(this.id, { fiscalCode: this.form.get('fiscalCode').value }).subscribe();
  }

  directLogin() {
    this.authServices.directLogin(this.user.email).subscribe((res) => {});
  }

  save() {}
}
