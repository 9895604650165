import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ShowAgentService, SitesService, UserMetaEntity, UserMetaService } from '@fi/app/client';
import {
  APPLICATION_ID,
  FiPermissions,
  FiRoles,
  IAppointmentLimit,
  ISite,
  IUser,
  IUserMeta,
  getUpline,
} from '@fi/app/core';
import { UsersService } from '@fi/app/core/services/users.service';
import { setParentForm } from '@fi/app/core/utils';
import { AppConfigService } from '@fi/app/core/app-config/app-config.service';
import { AddShowAgentModalComponent } from '@fi/app/modals/add-show-agent-modal/add-show-agent-modal';
import { ConfirmModalComponent } from '@fi/app/ui';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { Observable, lastValueFrom } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from '../../module.options';
import { AuthServices } from '@fi/app/core/services/auth.services';
@Component({
  selector: 'utenti-additional-data-form',
  template: `
    <form [formGroup]="form" novalidate>
      <div>
        <div class="row">
          <div class="col-12">
            <header class="page-header">
              <h2 class="tx-title-2">Zona</h2>
            </header>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-6 col-md-6">
            <app-input-address
              [options]="{ label: 'Indirizzo sede *' }"
              formControlName="address"
            ></app-input-address>
          </div>

          <div class="col-sm-6 col-md-6">
            <fullstack-input-box
              [ngModelOptions]="{ standalone: true }"
              [options]="{ label: 'Raggio *' }"
              [(ngModel)]="radius"
              [disabled]="!canEditRadius"
              (ngModelChange)="setRadius($event)"
            ></fullstack-input-box>
          </div>

          <div class="col-sm-12 mt-4" *ngIf="!utentiModuleOption?.excludeField?.site">

          <p-dropdown
            [options]="sites$ | async"
            [filter]="true"
            formControlName="site"
            [filterFields]="['name']"
            optionValue="_id"
            optionLabel="name"
            [showClear]="true"
            styleClass="w-64"
            placeholder="Sede di riferimento"
            filterPlaceholder="Nome sede"
          >
            <ng-template let-item pTemplate="selectedItem">
              <div class="flex align-items-center gap-2">
                <div *ngIf="item">{{ item?.name }}</div>
                <div *ngIf="!item">Sede di riferimento</div>
              </div>
            </ng-template>

            <ng-template let-item pTemplate="item">
              <div class="flex align-items-center gap-2 succ">
                <div>{{ item?.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>

          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <header class="page-header border-bottom-0">
              <h2 class="tx-title-3">Descrizione</h2>
            </header>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <fullstack-input-textarea
              [options]="{ label: 'Descrizione utente', rows: 6, cols: 80 }"
              formControlName="description"
            ></fullstack-input-textarea>
          </div>
        </div>
        <div *permissions="['appointment_config_manager']">
          <div class="row mb-4">
            <div class="col-sm-12">
              <header class="page-header">
                <h2 class="tx-title-3">Appuntamenti</h2>
              </header>
            </div>
          </div>
          <div class="row" formGroupName="appointmentLimit">
            <div class="col-sm-12 col-md-4">
              <fullstack-input-box [options]="{ label: 'Settimanali' }" formControlName="week"></fullstack-input-box>
            </div>

            <div class="col-sm-12 col-md-4">
              <fullstack-input-box [options]="{ label: 'Mensili' }" formControlName="month"></fullstack-input-box>
            </div>

            <div class="col-sm-12 col-md-4">
              <fullstack-input-box [options]="{ label: 'Annuali' }" formControlName="year"></fullstack-input-box>
            </div>
          </div>
        </div>

        <!-- Network -->
        <div class="row my-4">
          <div class="col-6">
            <header class="page-header mb-2">
              <h2 class="tx-title-3">Network</h2>
            </header>
            <fullstack-input-select-box formControlName="parent">
              <sb-option [key]="'id'" [value]="i" *ngFor="let i of parentsList$ | async">{{ i.email }}</sb-option>
            </fullstack-input-select-box>
          </div>
          <div  class="col-6">
            <!-- Show agent users -->
            <header class="page-header mb-2">
              <h2 class="tx-title-3">
                Show agent users
                <span class="cursor-pointer" (click)="addShowAgent()"><i class="fas fa-edit"></i></span>
              </h2>
            </header>
            <div class="row">
              <div class="col-sm-6">
                <div *ngFor="let sr of $any(userMeta?.showAgentUsers)" class="flex">
                  <div *ngIf="sr" class="flex-1">
                    {{ sr.firstName }} {{ sr.lastName }} {{ userMeta.defaultShowAgent ? '(Tutti gli immobili)' : '' }}
                  </div>
                  <div class="flex-1 text-right">
                    <i class="far fa-trash-alt mx-2 cursor-pointer" (click)="removeShowAgent(sr)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-sm-12">
          <header class="page-header">
            <h2 class="tx-title-3">Disponibilità</h2>
          </header>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <fullstack-input-check-box
            formControlName="isUnavailable"
            [options]="{ label: 'Non disponibile' }"
          ></fullstack-input-check-box>
          <small style="color: #f44336;" *ngIf="(isUnAvaiableFormValue$ | async) === true"
            >L'utente non è disponibile, gli slot orari verrano ignorati.</small
          >
          <fullstack-day-time-slot formControlName="availabilitySlots"></fullstack-day-time-slot>
        </div>
        <div class="col-4" *ngIf="!utentiModuleOption?.excludeField?.acceptStar">
          <fullstack-input-check-box
            formControlName="acceptStar"
            [options]="{ label: 'Accetta appuntamenti stella' }"
          ></fullstack-input-check-box>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-sm-12">
          <header class="page-header">
            <h2 class="tx-title-3">Integrazioni</h2>
          </header>
        </div>
      </div>
      <div class="row gy-4">
        <div class="col-6">
          <fullstack-input-box
            [options]="{ label: 'Fulltransfer Client Id' }"
            formControlName="fulltransferClientId"
          ></fullstack-input-box>
        </div>
        <div class="col-6">
          <fullstack-input-box
            [options]="{ label: 'Fulltransfer Client Secret' }"
            formControlName="fulltransferClientSecret"
          ></fullstack-input-box>
        </div>
        <div class="col-6">
          <fullstack-input-box
            [options]="{ label: 'La tua mail di google' }"
            formControlName="googleMail"
          ></fullstack-input-box>
          </div>

          <div class="col-6">

          <fullstack-input-box
            [options]="{ label: 'Id del calendario condiviso' }"
            formControlName="googleCalendarId"
          ></fullstack-input-box>
        </div>
      </div>


      <div class="row" *ngIf="false && !utentiModuleOption?.excludeField?.bc">
        <div class="col-sm-12 col-md-4">
          <header class="page-header border-bottom-0">
            <h2 class="tx-title-4">Business Central</h2>
            <div *ngIf="bcId; else showBC">
              <p class="text-muted">ID: {{ bcId }}</p>
            </div>
            <ng-template #showBC>
              <button class="btn btn-secondary rounded" (click)="activateBC(userId)">Attiva su Business Central</button>
            </ng-template>
          </header>
        </div>
      </div>
    </form>
  `,
})
export class UtentiAdditionalDataFrom implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  form: IFormGroup<IUserMeta>;
  fb: IFormBuilder = new UntypedFormBuilder();
  isUnAvaiableFormValue$;
  sites$: Observable<ISite[]>;
  parentsList$: Observable<IUser[]>;
  APPLICATION_ID = APPLICATION_ID;
  currentAppId: string;
  userId: string;
  bcId: string;
  radius: number;
  roles: FiRoles[] = [];
  canEditRadius = false;
  userMeta: UserMetaEntity;
  canEditShowAgent;

  constructor(
    private usersService: UsersService,
    private userMetaService: UserMetaService,
    private authService: AuthServices,
    @Inject(UTENTI_CONFIG_PROVIDER)
    public utentiModuleOption: UtentiModuleOptions,
    private activatedRoute: ActivatedRoute,
    private appConfigService: AppConfigService,
    private sitesService: SitesService,
    private dialog: MatDialog,
    private showAgentService: ShowAgentService,
  ) {
    this.form = this.fb.group<IUserMeta>({
      appointmentLimit: this.fb.group<IAppointmentLimit>({
        month: new UntypedFormControl(null, []),
        year: new UntypedFormControl(null, []),
        week: new UntypedFormControl(null, []),
      }),
      address: new UntypedFormControl(null, [Validators.required]),
      parent: new UntypedFormControl(null, []),
      candidateType: new UntypedFormControl([], []),
      availabilitySlots: new UntypedFormControl(null, []),
      isUnavailable: new UntypedFormControl(null, []),
      description: new UntypedFormControl(null, []),
      site: new UntypedFormControl(null, []),
      acceptStar: new UntypedFormControl(null, []),
      fulltransferClientId: new UntypedFormControl(null, []),
      fulltransferClientSecret: new UntypedFormControl(null, []),
      googleMail: new UntypedFormControl(null, []),
      googleCalendarId: new UntypedFormControl(null, []),
    });
    this.isUnAvaiableFormValue$ = this.form.controls['isUnavailable'].valueChanges;
    this.sites$ = this.sitesService.findAll();
  }

  ngOnInit(): void {
    this.currentAppId = this.appConfigService.getApplicationId();

    if (this.currentAppId === APPLICATION_ID.FI) {
      this.form.get('appointmentLimit').patchValue({ month: 4, week: 1, year: 48 });
    }
    this.activatedRoute.queryParams
      .pipe(
        tap((params) => {
          this.userId = params.id;
        }),
        switchMap((_) => this.authService.getUserRoles()),
        tap((roles) => {
          this.roles = roles as FiRoles[];
          this.canEditRadius = this.roles.some(
            (r) =>
              [
                FiRoles.IS_REGIONAL_MANAGER,
                FiRoles.IS_AREA_MANAGER,
                FiRoles.IS_STORE_MANAGER,
                FiRoles.IS_ADMIN,
                FiRoles.IS_MAINTAINER,
                FiPermissions.CAN_VIEW_USERS_DETAILS,
              ].indexOf(r) >= 0,
          );
        }),
        switchMap((_) => this.userMetaService.findByFusionUserId(this.userId)),
        switchMap((user) => {
          const registration = user.registrations.find((r) => r.applicationId == APPLICATION_ID.FI);
          const roles = registration.roles;
          this.canEditShowAgent = roles.find((r) => getUpline(FiRoles.IS_AGENTE_SENIOR).includes(<FiRoles>r));

          const filterRole = this.usersService.calculateNetworkPriority(user, this.appConfigService.getApplicationId());
          this.parentsList$ = this.usersService.getUsersListDownline(['is_admin'], filterRole).pipe(
            map((parentList) => {
              return parentList.filter((l) => l.id != this.userId);
            }),
          );
          setParentForm(this.parentForm, this.form);

          return this.loadUserMeta();
        }),
      )
      .subscribe();
  }

  loadUserMeta() {
    return this.userMetaService.findByFusionUserId(this.userId).pipe(
      tap((val) => {
        this.bcId = val.bcId;
        this.userMeta = val;
        this.parentForm.patchValue(val);
        this.radius = val?.address?.radius;
      }),
    );
  }

  setRadius(radius: number) {
    this.form.get('address').value.radius = radius;
  }

  activateBC(userId: string) {
    lastValueFrom(this.usersService.activateBC(userId));
  }

  addShowAgent() {
    this.dialog
      .open(AddShowAgentModalComponent, {
        data: { userMeta: this.userMeta },
      })
      .afterClosed()
      .subscribe(() => {
        this.loadUserMeta().subscribe();
      });
  }

  removeShowAgent(showAgentUser: IUser) {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Confermi l'eliminazione dello show agent?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap(() =>
          this.showAgentService.removeShowAgentUser({ originUser: this.userId, showAgentUser: showAgentUser.id }),
        ),
        switchMap(() => this.loadUserMeta()),
      )
      .subscribe();
  }
}
